import React from 'react';

const SvgSocialFacility = (props: React.SVGAttributes<{}>) => (
  <svg width="1em" height="1em" viewBox="0 0 15 15" {...props}>
    <path
      d="M13.5 4a.5.5 0 01-.22-.05L7.5 1 1.72 3.93A.514.514 0 011.28 3L7.5-.08 13.72 3a.512.512 0 01-.22 1zM5.028 6.198c-.55 0-.996-.492-.996-1.1 0-.606.446-1.098.996-1.098s.996.492.996 1.099-.446 1.099-.996 1.099zm1.895 2.666L5.276 7.04a.322.322 0 00-.252-.11H3.032a.322.322 0 00-.233.103L1.132 8.857a.408.408 0 00-.059.534.324.324 0 00.485.066.365.365 0 00.06-.066L3.031 7.81l-1.328 4.249h1.328v2.44a.406.406 0 000 .109c.036.202.215.333.398.293a.358.358 0 00.266-.293v-2.55h.664v2.565c.037.202.215.333.398.292a.358.358 0 00.266-.402v-2.454h1.328L5.024 7.809l1.42 1.582c.065.08.157.125.253.125.183-.016.323-.186.319-.388a.396.396 0 00-.093-.264zm4.105-2.666c-.55 0-.996-.492-.996-1.1 0-.606.446-1.098.996-1.098s.996.492.996 1.099-.446 1.099-.996 1.099zm2.895 2.666L12.276 7.04a.322.322 0 00-.252-.11h-1.992a.322.322 0 00-.233.103L8.132 8.857a.408.408 0 00-.059.534.324.324 0 00.485.066.365.365 0 00.06-.066l1.414-1.582-1.328 4.249h1.328v2.44a.406.406 0 000 .109c.036.202.215.333.398.293a.358.358 0 00.266-.293v-2.55h.664v2.565c.037.202.215.333.398.292a.358.358 0 00.266-.402v-2.454h1.328l-1.328-4.249 1.42 1.582c.065.08.157.125.253.125.183-.016.323-.186.319-.388a.396.396 0 00-.093-.264z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgSocialFacility;

