export { default as accommodation } from './accommodation';
export { default as airport } from './airport';
export { default as alcohol } from './alcohol';
export { default as alternative_medicine } from './alternative_medicine';
export { default as antiques } from './antiques';
export { default as archaeological_site } from './archaeological_site';
export { default as art_gallery } from './art_gallery';
export { default as art_shop } from './art_shop';
export { default as arts_center } from './arts_center';
export { default as association } from './association';
export { default as atm } from './atm';
export { default as attraction } from './attraction';
export { default as bank } from './bank';
export { default as bar } from './bar';
export { default as barber } from './barber';
export { default as beach } from './beach';
export { default as beautysalon } from './beautysalon';
export { default as bed_breakfast } from './bed_breakfast';
export { default as beverages } from './beverages';
export { default as bicycle_rental } from './bicycle_rental';
export { default as bicycle_store } from './bicycle_store';
export { default as biergarten } from './biergarten';
export { default as books } from './books';
export { default as bread } from './bread';
export { default as brothel } from './brothel';
export { default as bus_station } from './bus_station';
export { default as bus_stop } from './bus_stop';
export { default as butcher } from './butcher';
export { default as cablecar } from './cablecar';
export { default as camping } from './camping';
export { default as car_dealer } from './car_dealer';
export { default as car_rental } from './car_rental';
export { default as car_repair } from './car_repair';
export { default as car_sharing } from './car_sharing';
export { default as caravan_site } from './caravan_site';
export { default as casino } from './casino';
export { default as castle } from './castle';
export { default as cave } from './cave';
export { default as chalet } from './chalet';
export { default as chemist } from './chemist';
export { default as cinema } from './cinema';
export { default as clothes } from './clothes';
export { default as coffee } from './coffee';
export { default as college } from './college';
export { default as communitycentre } from './communitycentre';
export { default as company } from './company';
export { default as computers } from './computers';
export { default as confectionery } from './confectionery';
export { default as convenience_store } from './convenience_store';
export { default as copyshop } from './copyshop';
export { default as court } from './court';
export { default as culture } from './culture';
export { default as currencyexchange } from './currencyexchange';
export { default as deli } from './deli';
export { default as dentist } from './dentist';
export { default as department_store } from './department_store';
export { default as diy } from './diy';
export { default as doctor } from './doctor';
export { default as dormitory } from './dormitory';
export { default as drinkingwater } from './drinkingwater';
export { default as driving_school } from './driving_school';
export { default as dry_cleaning } from './dry_cleaning';
export { default as education } from './education';
export { default as electronics } from './electronics';
export { default as elevator } from './elevator';
export { default as embassy } from './embassy';
export { default as employment_agency } from './employment_agency';
export { default as erotic } from './erotic';
export { default as escalator } from './escalator';
export { default as fastfood } from './fastfood';
export { default as ferry } from './ferry';
export { default as flowers } from './flowers';
export { default as food } from './food';
export { default as fuel } from './fuel';
export { default as funeral_home } from './funeral_home';
export { default as furniture } from './furniture';
export { default as garden_center } from './garden_center';
export { default as gifts } from './gifts';
export { default as government_office } from './government_office';
export { default as greengrocer } from './greengrocer';
export { default as guest_house } from './guest_house';
export { default as health } from './health';
export { default as hearing_aids } from './hearing_aids';
export { default as hiking } from './hiking';
export { default as hospital } from './hospital';
export { default as hostel } from './hostel';
export { default as hotel } from './hotel';
export { default as house } from './house';
export { default as icecream } from './icecream';
export { default as industry } from './industry';
export { default as instruments } from './instruments';
export { default as insurance } from './insurance';
export { default as interior_decoration } from './interior_decoration';
export { default as jewelry } from './jewelry';
export { default as kindergarten } from './kindergarten';
export { default as kiosk } from './kiosk';
export { default as laundry } from './laundry';
export { default as lawyer } from './lawyer';
export { default as leisure } from './leisure';
export { default as library } from './library';
export { default as mall } from './mall';
export { default as marina } from './marina';
export { default as massage } from './massage';
export { default as medical_store } from './medical_store';
export { default as memorial } from './memorial';
export { default as mobile_phones } from './mobile_phones';
export { default as money } from './money';
export { default as motel } from './motel';
export { default as museum } from './museum';
export { default as music } from './music';
export { default as newsagent } from './newsagent';
export { default as ngo } from './ngo';
export { default as nightclub } from './nightclub';
export { default as occupational_therapist } from './occupational_therapist';
export { default as official } from './official';
export { default as ophthalmologist } from './ophthalmologist';
export { default as organic_food } from './organic_food';
export { default as other } from './other';
export { default as park } from './park';
export { default as parking } from './parking';
export { default as pet_store } from './pet_store';
export { default as pharmacy } from './pharmacy';
export { default as photography } from './photography';
export { default as physiotherapist } from './physiotherapist';
export { default as place_of_worship } from './place_of_worship';
export { default as playground } from './playground';
export { default as police } from './police';
export { default as political_party } from './political_party';
export { default as post_office } from './post_office';
export { default as psychotherapist } from './psychotherapist';
export { default as pub } from './pub';
export { default as public_art } from './public_art';
export { default as public_transport } from './public_transport';
export { default as restaurant } from './restaurant';
export { default as sauna } from './sauna';
export { default as school } from './school';
export { default as second_hand } from './second_hand';
export { default as shoes } from './shoes';
export { default as shopping } from './shopping';
export { default as soccer } from './soccer';
export { default as social_facility } from './social_facility';
export { default as speech_therapist } from './speech_therapist';
export { default as sport } from './sport';
export { default as sports_center } from './sports_center';
export { default as sports_shop } from './sports_shop';
export { default as stadium } from './stadium';
export { default as stationery } from './stationery';
export { default as stripclub } from './stripclub';
export { default as subway_station } from './subway_station';
export { default as supermarket } from './supermarket';
export { default as swimming } from './swimming';
export { default as tailor } from './tailor';
export { default as tattoo } from './tattoo';
export { default as tea_shop } from './tea_shop';
export { default as textiles } from './textiles';
export { default as theater } from './theater';
export { default as themepark } from './themepark';
export { default as tobacco } from './tobacco';
export { default as toilets } from './toilets';
export { default as tools } from './tools';
export { default as tourism } from './tourism';
export { default as townhall } from './townhall';
export { default as toys } from './toys';
export { default as train } from './train';
export { default as train_station } from './train_station';
export { default as tram_stop } from './tram_stop';
export { default as transport } from './transport';
export { default as travel_agency } from './travel_agency';
export { default as undefined } from './undefined';
export { default as university } from './university';
export { default as variety_store } from './variety_store';
export { default as veterinary } from './veterinary';
export { default as video_store } from './video_store';
export { default as viewpoint } from './viewpoint';
export { default as zoo } from './zoo';
