import React from 'react';

const SvgPlaceOfWorship = (props: React.SVGAttributes<{}>) => (
  <svg width="1em" height="1em" viewBox="0 0 15 15" {...props}>
    <g fill="#000" fillRule="evenodd">
      <path d="M7.5 5.631a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM4.26 14c-.42 0-.992.232-1.283.523l.046-.046c-.289.289-.181.523.224.523h6.006c.413 0 .83-.33.928-.722l.138-.556c.1-.399-.117-.881-.477-1.073l-2.184-1.166c-.363-.194-.593-.678-.51-1.088L7.5 8.63l1.475 1.475c.29.29.76.29 1.061-.01l1.928-1.928a.747.747 0 00.013-1.06l.046.047a.744.744 0 00-1.048.002L9.5 8.63l-1.536-1.92c-.256-.32-.79-.514-1.2-.432l-1.027.205c-.407.082-.8.483-.876.894l-.722 3.876c-.077.412.17.883.544 1.05L8.5 14H4.26z" />
      <path d="M13.5 4a.5.5 0 01-.22-.05L7.5 1 1.72 3.93A.514.514 0 011.28 3L7.5-.08 13.72 3a.512.512 0 01-.22 1z" />
    </g>
  </svg>
);

export default SvgPlaceOfWorship;

