import React from 'react';

const SvgSauna = (props: React.SVGAttributes<{}>) => (
  <svg width="1em" height="1em" viewBox="0 0 15 15" {...props}>
    <path
      d="M11.08 8.363C13.417 8.872 15 9.862 15 11c0 1.657-3.358 3-7.5 3C3.358 14 0 12.657 0 11c0-1.435 2.52-2.635 5.886-2.93.107.368.178.737.114.93a.656.656 0 01-.022.057C3.245 9.272 1.5 10.06 1.5 11c0 1.105 2.41 2 6 2s6-.895 6-2c0-.711-1-1.336-2.67-1.69.074-.098.134-.202.17-.31.053-.158.075-.388.08-.637zM12 1S9 2 9 4.5s1 2 1 4-3 3-3 3 4-1 4-3-1-2.5-1-4S12 1 12 1zM9 1S6 2 6 4.5s1 2 1 4-3 3-3 3 4-1 4-3-1-2.5-1-4S9 1 9 1z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgSauna;

