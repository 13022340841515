import React from 'react';

const SvgBicycleRental = (props: React.SVGAttributes<{}>) => (
  <svg width="1em" height="1em" viewBox="0 0 15 15" {...props}>
    <path
      d="M7.5 2c-.676-.01-.676 1.01 0 1H9v1.266L6.197 6.6 5.223 4H5.5c.676.01.676-1.01 0-1h-2c-.676-.01-.676 1.01 0 1h.652l.891 2.375A3.45 3.45 0 003.5 6 3.508 3.508 0 000 9.5C0 11.427 1.573 13 3.5 13S7 11.427 7 9.5c0-.67-.2-1.291-.53-1.824l2.821-2.35.463 1.16C8.71 7.094 8 8.211 8 9.5c0 1.927 1.573 3.5 3.5 3.5S15 11.427 15 9.5 13.427 6 11.5 6c-.283 0-.554.043-.818.107L10 4.402V2.5a.5.5 0 00-.5-.5h-2zm-4 5a2.48 2.48 0 011.555.553L3.18 9.115c-.511.428.127 1.195.64.77l1.875-1.563c.188.352.305.75.305 1.178C6 10.887 4.887 12 3.5 12A2.492 2.492 0 011 9.5C1 8.113 2.113 7 3.5 7zm8 0C12.887 7 14 8.113 14 9.5S12.887 12 11.5 12A2.492 2.492 0 019 9.5c0-.877.447-1.642 1.125-2.088l.91 2.274c.246.623 1.18.25.93-.372l-.908-2.27C11.2 7.02 11.348 7 11.5 7z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgBicycleRental;

