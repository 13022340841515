import React from 'react';

const SvgGlobeIcon = (props: any) => (
  <svg width="1em" height="1em" viewBox="0 0 33 33" {...props}>
    <defs>
      <path
        d="M23.835 24.013c.325-.845.38-2.037.165-3.578h-2.857c-1.524 0-1.524-1.637 0-4.91.476-1.163 1.428-2.39 2.857-3.683 2.143-1.938 0-1.841.429-1.841.078 0 .245.06.468.194A10.484 10.484 0 0 0 16.5 6a10.502 10.502 0 0 0-9.61 6.263c2.407 1.505 4.077 1.848 5.01 1.031 1.479-1.294 2.465.647 1.972 1.941-.493 1.294-.986 2.589-2.957 2.589-1.315 0-2.3 1.078-2.958 3.235v1.547A10.487 10.487 0 0 0 16.5 27c2.854 0 5.443-1.139 7.335-2.987zM16.5 29C9.596 29 4 23.404 4 16.5S9.596 4 16.5 4 29 9.596 29 16.5 23.404 29 16.5 29z"
        id="GlobeIcon_svg__a"
      />
    </defs>
    <use fill="#37404D" fillRule="nonzero" xlinkHref="#GlobeIcon_svg__a" />
  </svg>
);

export default SvgGlobeIcon;
