import React from 'react';

const SvgMapPinIcon = (props: any) => (
  <svg width="1em" height="1em" viewBox="0 0 33 33" {...props}>
    <defs>
      <path
        d="M25.55 12.37c0 1.28-.19 2.329-.567 3.147L16.58 30.37 8.147 15.517c-.377-.818-.566-1.867-.566-3.147 0-2.476.88-4.594 2.643-6.357 1.762-1.762 3.881-2.643 6.357-2.643 2.475 0 4.594.881 6.356 2.643C24.7 7.776 25.57 9.894 25.55 12.37zm-2.354 2.244c.226-.526.353-1.274.353-2.244.017-1.955-.642-3.559-2.026-4.942-1.388-1.388-2.998-2.058-4.942-2.058s-3.555.67-4.943 2.058c-1.388 1.387-2.057 2.998-2.057 4.942 0 .97.127 1.717.353 2.243l6.643 11.7 6.619-11.699zM20 12c0 1.463-.343 2.287-1 3-.713.657-1.537 1-3 1-.463 0-1.287-.343-2-1-.657-.713-1-1.537-1-3 0-.463.343-1.287 1-2 .713-.657 1.537-1 2-1 1.463 0 2.287.343 3 1 .657.713 1 1.537 1 2z"
        id="MapPinIcon_svg__a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#37404D" fillRule="nonzero" xlinkHref="#MapPinIcon_svg__a" />
    </g>
  </svg>
);

export default SvgMapPinIcon;
