import React from 'react';

const SvgUserIcon = (props: any) => (
  <svg width="1em" height="1em" viewBox="0 0 33 33" {...props}>
    <defs>
      <path
        d="M24.394 29c-1.536-.687-2.674-1.396-3.424-2.146-1.094-1.094-1.636-2.566-1.636-4.354 0-.842.398-1.673 1.134-2.505.451-.51.776-1.286.952-2.342.088-.524.309-.967.681-1.286.229-.196.47-.346.727-.446.026-.043.058-.103.093-.177.12-.256.21-.694.252-1.228 0-.518-.073-.688-.098-.698l-.686-.305.102-.744.457-3.354c.122-1.094-.3-2.275-1.325-3.567C20.663 4.636 18.93 4 16.342 4c-2.585 0-4.302.634-5.237 1.84-1.01 1.302-1.449 2.5-1.37 3.581.101.953.252 2.062.454 3.325l.08.504-.624.625c-.014.024-.03.055-.048.09a1.222 1.222 0 0 0-.089.48c.044.605.135 1.043.254 1.299.035.074.067.134.093.177.257.1.499.25.727.446.372.32.594.762.672 1.238.224 1.033.571 1.824 1.026 2.383.696.854 1.07 1.686 1.07 2.512 0 1.783-.526 3.25-1.588 4.344-.735.756-1.877 1.467-3.44 2.156H2.781a59.163 59.163 0 0 1 3.01-1.145c2.275-.823 3.795-1.642 4.536-2.405.677-.697 1.022-1.661 1.022-2.95 0-.299-.189-.72-.62-1.249-.67-.824-1.142-1.9-1.439-3.27-.015-.09-.024-.108-.01-.096a.51.51 0 0 0-.161-.104c-.537-.154-.877-.567-1.169-1.192-.239-.512-.378-1.174-.44-2.073 0-.554.094-1.036.298-1.445a2.86 2.86 0 0 1 .325-.527 61.547 61.547 0 0 1-.39-2.944c-.122-1.652.49-3.32 1.782-4.987C10.893 2.85 13.195 2 16.342 2c3.144 0 5.455.848 6.848 2.605 1.33 1.676 1.932 3.361 1.743 5.056l-.383 2.809c.429.484.622 1.204.62 2.117-.06.828-.198 1.49-.437 2.002-.291.625-.631 1.038-1.169 1.192a.51.51 0 0 0-.161.104c.014-.012.005.006-.01.097-.233 1.4-.702 2.518-1.427 3.338-.441.499-.632.897-.632 1.18 0 1.283.353 2.243 1.05 2.94.772.771 2.281 1.592 4.508 2.415 1.115.394 2.118.774 3.01 1.145h-5.508z"
        id="UserIcon_svg__a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#37404D" fillRule="nonzero" xlinkHref="#UserIcon_svg__a" />
    </g>
  </svg>
);

export default SvgUserIcon;
